import { forwardRef, type ComponentPropsWithoutRef } from "react"
import { tv, type VariantProps } from "./tv"

export const input = tv({
  base: "flex w-full items-center justify-start border border-borders-medium bg-bg-primary transition-colors selection:bg-orange-10 placeholder:font-medium placeholder:text-text-light hover:border-borders-stronger focus-visible:border-gray-40 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-text-tertiary aria-[invalid=true]:border-red aria-[invalid=true]:focus-visible:border-gray-40",
  variants: {
    size: {
      md: "h-10 rounded-lg px-3 text-[16px]",
      sm: "h-6 rounded-md px-1.5 text-base leading-tight",
    },
    isNumber: {
      // Remove arrows on number input
      true: "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
    },
  },
  defaultVariants: {
    size: "md",
  },
})

interface Props
  extends Omit<ComponentPropsWithoutRef<"input">, "size">,
    VariantProps<typeof input> {}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, type, size, ...props }, ref) => (
    <input
      type={type}
      className={input({ className, size, isNumber: type === "number" })}
      ref={ref}
      {...props}
    />
  ),
)
Input.displayName = "Input"
